import { useRouter } from 'next/router';
import { trackEventAmplitude } from '@/utils/trackEventsAmplitude';

export const useAuthNavigation = () => {
  const router = useRouter();

  const handleLoginAndVerifyNavigation = () => {
    const loginAndVerifyUri =
      '/api/auth/login?' + new URLSearchParams({ operation: 'verify', action: 'login' });

    trackEventAmplitude('auth', 'USER_BEING_OPERATION', {
      event_properties: {
        operation: 'login',
         action: 'login'
      },
    });
    router.push(loginAndVerifyUri);
  };

  const handleRegisterAndVerifyNavigation = () => {
    const registerAndVerifyUri =
      '/api/auth/login?' + new URLSearchParams({ operation: 'verify', action: 'signup' });

    trackEventAmplitude('auth', 'USER_BEING_OPERATION', {
      event_properties: {
        operation: 'login',
        action: 'signup'
      },
    });
    router.push(registerAndVerifyUri);
  };

  const handleLogoutNavigation = () => {
    trackEventAmplitude('auth', 'USER_LOGOUT');
    router.push('/api/auth/logout');
  };

  return { handleLoginAndVerifyNavigation, handleRegisterAndVerifyNavigation, handleLogoutNavigation };
};
