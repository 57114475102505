import { Icon } from '@/components/atoms/Icons';
import { TemplateProps } from '@/hooks/useTypesJsonData';
import { useCallback, useState } from 'react';
import { useRouter } from 'next/router';
import navbarData from '@/public/data/data.json';
import { useAuthNavigation } from '@/hooks/useAuthNavigation';
import { useUser } from '@auth0/nextjs-auth0/client';

export const NavbarLargeScreenLogin = () => {
  const [isOpen, setIsOpen] = useState(false);
  const router = useRouter();
  const data: TemplateProps = navbarData;
  const options = data.navbar.navbarOptionsLogin || [];
  const auth = data.navbar.authLogin || [];
  const { handleLogoutNavigation } = useAuthNavigation();
  const user = useUser();
  const currentPath = router.pathname;
  console.log(currentPath);

  const handleOnKeyDowned = useCallback(
    (event: React.KeyboardEvent<HTMLButtonElement>) => {
      if (event.key === 'Enter') {
        router.push('/none');
      }
    },
    [router]
  );

  const handleRouterOptions = (type: string, url?: string) => {
    if (type === 'logout') {
      handleLogoutNavigation();
    }
    if (type === 'validate') {
      window.open(url, '_blank');
    }
  };

  return (
    <div className="flex justify-center">
      <div className="flex items-center justify-between relative bg-white rounded-b-[1rem] h-[4.96rem] max-desktop-xl:w-[70%] max-tablet-md:w-[80%] max-tablet-xs:w-full max-tablet-xs:rounded-none w-[55.6%] transition-width duration-300 ease-in-out font-helvetica">
        <div className="ml-6">
          <nav>
            <ul className=" flex items-center text-bodyXs">
              <li className="relative mr-20 max-desktop-sm:mr-4 flex items-center transition-margin duration-300 ease-in-out">
                <Icon
                  name="digitoLetter"
                  width="5.66rem"
                  className="mr-2 max-desktop-sm:opacity-0 absolute"
                  height="1.65rem"
                />
                <Icon
                  name="digito"
                  className="scale-[0.48]  translate-x-[-0.84rem] opacity-0 max-desktop-sm:opacity-100"
                  width="3.42rem"
                  height="3.41rem"
                />
              </li>
              {options.map((opt, index) => {
                if (opt.isEnable) {
                  return (
                    <li
                      key={`navbar-options-${opt.id}`}
                      className="mr-3 cursor-pointer flex items-center"
                    >
                      <button
                        className={`${currentPath === opt.redirectUrl && 'text-primary-550 border-b-2 border-b-primary-550'} py-[0.4rem] px-[0.35rem]`}
                        type="button"
                        tabIndex={0}
                        aria-label="Ir a la página de destino"
                        onKeyDown={handleOnKeyDowned}
                        onClick={() =>
                          handleRouterOptions(opt.key, opt.redirectUrl)
                        }
                      >
                        {opt.label}
                      </button>
                      {index + 1 < options.length && (
                        <span className="text-[1.2rem] opacity-20 ml-3">•</span>
                      )}
                    </li>
                  );
                }
                return null;
              })}
            </ul>
          </nav>
        </div>

        <div className="flex items-center mt-2  mr-4 translate-x-4 gap-8">
          <Icon
            name="avatar"
            width="3rem"
            height="3rem"
            className="translate-x-6"
          />
          <Icon
            onClick={() => setIsOpen(!isOpen)}
            name="arrowSimple"
            className={`${isOpen ? '-rotate-90' : 'rotate-90'} mr-4  cursor-pointer transition-all duration-300 ease-in-out`}
            width="1.20rem"
            height="1.20rem"
          />
        </div>

        <div
          className={`transition-opacity duration-300 ease-in-out w-[10rem] bg-white shadow-Sm mt-[13.8rem] text-center border z-10 rounded-[0.5rem] absolute right-0 ${isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
        >
          <nav>
            <ul className="text-bodyXs flex-col items-center w-full">
              <li className="mt-2 text-primary-550">
                {user.user?.email
                  ? user.user.email.split('@')[0]
                  : 'Juan Perez'}
              </li>
              <hr className="mt-2" />
              {auth.map((au, index) => {
                if (au.isEnable) {
                  return (
                    <li key={au.key} className="mb-2 mt-2 cursor-pointer">
                      <button
                        type="button"
                        tabIndex={0}
                        aria-label="Ir a la página de destino"
                        onKeyDown={(event) => {
                          if (event.key === 'Enter') {
                            handleRouterOptions(au.key, au.redirectUrl);
                          }
                        }}
                        onClick={() =>
                          handleRouterOptions(au.key, au.redirectUrl)
                        }
                      >
                        {au.label}
                      </button>
                      {index < auth.length - 1 && <hr className="mt-2" />}
                    </li>
                  );
                }
                return null;
              })}
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};
