import { useResponsive } from '@/hooks/useResponsive';
import FooterLargeScreen from '@/components/molecules/footer/FooterLargeScreen';
import FooterSmallScreen from '@/components/molecules/footer/FooterSmallScreen';

export const Footer = () => {
  const { isMobileFooter, isTablet } = useResponsive();

  return (
    <>
      {isMobileFooter ? (
        <FooterSmallScreen />
      ) : (
        <FooterLargeScreen isTablet={isTablet} />
      )}
    </>
  );
};
