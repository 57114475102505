import { Footer } from '@/components/molecules/footer/Footer';
import { Navbar } from '@/components/molecules/navbar/Navbar';
import { NavbarPersona } from '@/components/molecules/navbar/NavbarPersona';
import { useRouter } from 'next/router';

interface TemplateProps {
  children: React.ReactNode;
}

export const Template = (props: TemplateProps) => {
  const { children } = props;
  const router = useRouter();
  const currentPath = router.pathname;

  return (
    <div className="flex flex-col min-h-screen relative">
      <div className="z-10">
        {currentPath === '/verify' ? <NavbarPersona /> : <Navbar />}
      </div>
      <main className="flex-grow">{children}</main>
      <div className="z-20">
        <Footer />
      </div>
    </div>
  );
};
