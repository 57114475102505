import Facebook from '@/public/img/svg/socialMedia/facebook-icon.svg';
import Instagram from '@/public/img/svg/socialMedia/instagram-icon.svg';
import Twitter from '@/public/img/svg/socialMedia/twitter-icon.svg';
import FacebookWhite from '@/public/img/svg/socialMedia/facebook-white.svg';
import InstagramWhite from '@/public/img/svg/socialMedia/instagram-white.svg';
import TwitterWhite from '@/public/img/svg/socialMedia/twitter-white.svg';
import Digito from '@/public/img/svg/digito-icon.svg';
import DigitoLetter from '@/public/img/svg/digito-letter-icon.svg';
import DigitoLetterWhite from '@/public/img/svg/digito-letter-white.svg';
import ArrowSimple from '@/public/img/svg/arrow-simple.svg';
import Close from '@/public/img/svg/close-icon.svg';
import Menu from '@/public/img/svg/menu-icon.svg';
import PersonaCardID from '@/public/img/svg/persona/card-id.svg';
import PersonaFaceCard from '@/public/img/svg/persona/face-card.svg';
import PersonaSquareFace from '@/public/img/svg/persona/square-face.svg';
import PersonaFingerprint from '@/public/img/svg/persona/fingerprint.svg';
import FourLineGray from '@/public/img/svg/four-line-decoration-gray.svg';
import PointDecorationYellow from '@/public/img/svg/point-decoration-yellow.svg';
import PdfRed from '@/public/img/svg/pdf-red.svg';
import Avatar from '@/public/img/svg/avatar.svg';
import Return from '@/public/img/svg/return.svg';
import MultiPoint from '@/public/img/svg/multi-point-purple.svg';
import ArrowLine from '@/public/img/svg/arrow-line.svg';
import CheckWhite from '@/public/img/svg/check-xl-white.svg';

interface IconsProps extends React.SVGProps<SVGSVGElement> {
  name:
    | 'personaCardId'
    | 'personaFaceCard'
    | 'personaSquareFace'
    | 'personaFingerprint'
    | 'digito'
    | 'digitoLetter'
    | 'digitoLetterWhite'
    | 'instagram'
    | 'facebook'
    | 'twitter'
    | 'instagramWhite'
    | 'facebookWhite'
    | 'twitterWhite'
    | 'arrowSimple'
    | 'menu'
    | 'pointDecorationYellow'
    | 'pdfRed'
    | 'fourLineGray'
    | 'avatar'
    | 'return'
    | 'multiPoint'
    | 'arrowLine'
    | 'checkWhite'
    | 'close';
}

export const Icon = (props: IconsProps) => {
  const { name, ...restProps } = props;

  const componentMap: Record<
    IconsProps['name'],
    React.FC<React.SVGProps<SVGSVGElement>>
  > = {
    personaCardId: PersonaCardID,
    personaFaceCard: PersonaFaceCard,
    personaSquareFace: PersonaSquareFace,
    personaFingerprint: PersonaFingerprint,
    digito: Digito,
    menu: Menu,
    digitoLetter: DigitoLetter,
    instagram: Instagram,
    twitter: Twitter,
    facebook: Facebook,
    digitoLetterWhite: DigitoLetterWhite,
    instagramWhite: InstagramWhite,
    twitterWhite: TwitterWhite,
    facebookWhite: FacebookWhite,
    arrowSimple: ArrowSimple,
    pointDecorationYellow: PointDecorationYellow,
    pdfRed: PdfRed,
    fourLineGray: FourLineGray,
    avatar: Avatar,
    return: Return,
    multiPoint: MultiPoint,
    arrowLine: ArrowLine,
    checkWhite: CheckWhite,
    close: Close,
  };

  const IconComponent = componentMap[name];

  return <IconComponent {...restProps} />;
};
