import { Icon } from '@/components/atoms/Icons';
import { TemplateProps } from '@/hooks/useTypesJsonData';
import { useCallback } from 'react';
import { useRouter } from 'next/router';
import navbarData from '@/public/data/data.json';
import { useAuthNavigation } from '@/hooks/useAuthNavigation';

export const NavbarLargeScreen = () => {
  const router = useRouter();
  const data: TemplateProps = navbarData;
  const options = data.navbar.navbarOptions || [];
  const auth = data.navbar.auth || [];
  const { handleLoginAndVerifyNavigation, handleRegisterAndVerifyNavigation } =
    useAuthNavigation();

  const handleOnKeyDowned = useCallback(
    (event: React.KeyboardEvent<HTMLButtonElement>) => {
      if (event.key === 'Enter') {
        router.push('/none');
      }
    },
    [router]
  );

  const handleSignupLogin = (type: string) => {
    if (type === 'login') {
      handleLoginAndVerifyNavigation();
    } else {
      handleRegisterAndVerifyNavigation();
    }
  };

  return (
    <div className="flex h-[4.96rem] bg-white w-full items-center justify-between font-helvetica">
      <div className="ml-6">
        <nav>
          <ul className="ml-1 flex text-bodyXs">
            {options.map((opt) => {
              if (opt.isEnable) {
                return (
                  <li
                    key={`navbar-options-${opt.id}`}
                    className="mr-8 cursor-pointer"
                  >
                    <button
                      type="button"
                      tabIndex={0}
                      aria-label="Ir a la página de destino"
                      onKeyDown={handleOnKeyDowned}
                      onClick={() => handleSignupLogin(opt.redirectUrl)}
                    >
                      {opt.label}
                    </button>
                  </li>
                );
              }
              return null;
            })}

            <li className="relative">
              <div className="w-0.5 h-7 rounded-t-full rounded-b-full bg-primary-500 absolute transition-opacity duration-200 ease-in-out opacity-0 max-[1120px]:opacity-100" />
            </li>
          </ul>
        </nav>
      </div>

      <div className="opacity-100 absolute flex w-full justify-center pointer-events-none transition-opacity duration-200 ease-in-out max-[1120px]:opacity-0">
        <Icon name="digito" width="3.40rem" height="3.41rem" />
      </div>

      <div>
        <nav>
          <ul className="flex text-bodyXs">
            {auth.map((au) => {
              if (au.isEnable) {
                return (
                  <li key={au.key} className="mr-8 cursor-pointer">
                    <button
                      type="button"
                      tabIndex={0}
                      aria-label="Ir a la página de destino"
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          handleSignupLogin(au.redirectUrl);
                        }
                      }}
                      onClick={() => handleSignupLogin(au.redirectUrl)}
                    >
                      {au.label}
                    </button>
                  </li>
                );
              }
              return null;
            })}
          </ul>
        </nav>
      </div>
    </div>
  );
};
