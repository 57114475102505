import { useResponsive } from '@/hooks/useResponsive';
import { NavbarLargeScreen } from '@/components/molecules/navbar/NavbarLargeScreen';
import { NavbarSmallScreen } from '@/components/molecules/navbar/NavbarSmallScreen';
import { NavbarSmallScreenLogin } from '@/components/molecules/navbar//NavbarSmallScreenLogin';
import { NavbarLargeScreenLogin } from '@/components/molecules/navbar//NavbarLargeScreenLogin';
import { useUser } from '@auth0/nextjs-auth0/client';

export const Navbar = () => {
  const { isMobile, isMobileLogin } = useResponsive();
  const user = useUser();

  return (
    <>
      {user.user ? (
        <div className="relative">
          {isMobileLogin ? (
            <NavbarSmallScreenLogin />
          ) : (
            <NavbarLargeScreenLogin />
          )}
        </div>
      ) : (
        <div className="relative">
          {isMobile ? <NavbarSmallScreen /> : <NavbarLargeScreen />}
        </div>
      )}
    </>
  );
};
