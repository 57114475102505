import { Icon } from '@/components/atoms/Icons';
import { useCallback } from 'react';
import { useRouter } from 'next/router';
import navbarData from '@/public/data/data.json';
import { TemplateProps } from '@/hooks/useTypesJsonData';
import { useState } from 'react';
import { useUser } from '@auth0/nextjs-auth0/client';
import { useAuthNavigation } from '@/hooks/useAuthNavigation';

export const NavbarSmallScreen = () => {
  const [isClose, setIsClose] = useState(true);
  const user = useUser();
  const router = useRouter();
  const data: TemplateProps = navbarData;
  const options = data.navbar.navbarOptionsLogin;
  const icons = data.navbar.navbarResponsive.icons || [];
  const polices = data.navbar.navbarResponsive.polices || [];
  const auth = data.navbar.navbarResponsive.buttons || [];
  const { handleLoginAndVerifyNavigation, handleRegisterAndVerifyNavigation } =
    useAuthNavigation();

  const handleNavigationClicked = useCallback(
    (redirectUri: string) => {
      router.push(redirectUri);
    },
    [router]
  );

  const handleOnKeyDowned = useCallback(
    (event: React.KeyboardEvent<HTMLButtonElement>) => {
      if (event.key === 'Enter') {
        router.push('/none');
      }
    },
    [router]
  );

  const handleSignupLogin = (type: string) => {
    if (type === 'login') {
      handleLoginAndVerifyNavigation();
    } else {
      handleRegisterAndVerifyNavigation();
    }
  };

  return (
    <div className="h-[4.80rem] bg-white w-full ">
      <div>
        <nav>
          <ul>
            <li className="flex justify-between items-center cursor-pointer  px-5 py-6">
              <Icon name="digitoLetter" width="5.66rem" height="1.65rem" />
              {isClose ? (
                <Icon name="menu" onClick={() => setIsClose(false)} />
              ) : (
                <Icon name="close" onClick={() => setIsClose(true)} />
              )}
            </li>

            <div
              className={` bg-white ${
                isClose
                  ? 'translate-x-[-60rem] opacity-0'
                  : 'opacity-100 translate-x-0 '
              } transition-all duration-200 ease-in-out pt-4 mt-[0.1rem]`}
            >
              {options.map((opt, index) => {
                if (opt.isEnable) {
                  return (
                    <li
                      key={`navbar-options-${opt.id}`}
                      className="cursor-pointer"
                    >
                      <div className="font-sohne text-[1.13rem]">
                        <div className="flex items-center justify-between px-6">
                          <button
                            type="button"
                            tabIndex={0}
                            aria-label="Ir a la página de destino"
                            onKeyDown={handleOnKeyDowned}
                            onClick={() =>
                              handleNavigationClicked(opt.redirectUrl)
                            }
                          >
                            {opt.label}
                          </button>
                          <Icon
                            width="1.20rem"
                            height="1.20rem"
                            name="arrowSimple"
                          />
                        </div>
                      </div>
                      {index + 1 < options.length && (
                        <hr className="border-t-1 border-gray border-dashed my-3" />
                      )}
                    </li>
                  );
                }
                return null;
              })}
            </div>
          </ul>
        </nav>
      </div>

      <div
        className={`bg-white ${
          isClose
            ? 'translate-x-[-60rem] opacity-0'
            : 'opacity-100 translate-x-0'
        } transition-all duration-200 ease-in-out pb-10`}
      >
        <div className="flex items-center justify-center pt-5 gap-4">
          {icons.map((icon) => (
            <Icon
              onClick={() => window.open('https://www.instagram.com', '_blank')}
              name={icon.name as 'facebook'}
              className="cursor-pointer"
              key={icon.key}
            />
          ))}
        </div>

        <div className="flex-col font-nunito font-bold text-center mt-8 text-primary-500">
          <ul>
            {polices.map((police) => (
              <li key={police.key} className="mb-5 cursor-pointer">
                {police.label}
              </li>
            ))}
          </ul>
        </div>

        <hr className="border-t-1 border-gray  my-6 mb-12" />
        <div>
          <nav>
            <ul className="flex justify-center gap-10 items-center">
              {auth.map((au) => {
                if (au.isEnable) {
                  if (au.key === 'logout' && !user.user) {
                    return null;
                  }
                  return (
                    <li key={au.key} className="cursor-pointer">
                      <button
                        className={` ${
                          au.key === 'login'
                            ? 'bg-primary-500 w-[11rem] h-[2.6rem] text-white rounded-full'
                            : 'text-primary-500'
                        } font-nunito font-bold text-[1rem]`}
                        type="button"
                        tabIndex={0}
                        aria-label="Ir a la página de destino"
                        onKeyDown={(event) => {
                          if (event.key === 'Enter') {
                            router.push(au.redirectUrl);
                          }
                        }}
                        onClick={() => handleSignupLogin(au.redirectUrl)}
                      >
                        {au.label}
                      </button>
                    </li>
                  );
                }
                return null;
              })}
            </ul>
          </nav>
        </div>
      </div>

      <div
        onClick={() => setIsClose(!isClose)}
        className={`${
          isClose
            ? 'translate-x-[-60rem] opacity-0'
            : 'opacity-100 translate-x-0'
        } transition-all duration-200 ease-in-out bg-primary-550 w-full  h-screen opacity-80`}
      />
    </div>
  );
};
