import { Icon } from '@/components/atoms/Icons';
import { useRouter } from 'next/router';

export const NavbarPersona = () => {
  const router = useRouter();

  return (
    <div className="relative flex h-[4.96rem] w-full items-center  px-2 font-helvetica">
      <div className="absolute ml-6">
        <nav>
          <ul className="ml-1 flex text-bodyXs">
            <li className="mr-8 flex cursor-pointer items-center gap-4">
              <Icon
                width="1rem"
                height="1rem"
                name="arrowLine"
                className="rotate-180"
              />
              <button
                type="button"
                tabIndex={0}
                aria-label="Ir a la página de destino"
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    router.push('/');
                  }
                }}
                onClick={() => router.push('/')}
              >
                Regresar
              </button>
            </li>
          </ul>
        </nav>
      </div>

      <div className="flex w-full justify-center">
        <Icon name="digito" width="3.40rem" height="3.41rem" />
      </div>
    </div>
  );
};
